import '../Style.css';
import React from 'react';
import Moment from 'moment';
import Loader from './loader';
import { useState } from 'react';
export const API_SEARCH_PATH = window.API_URL+'search';

function FutureAvailability(data) {
    let future_array = '';
    if(data != 'undefined') {
        future_array = data.future
    }
    const [future, setFuture] = useState(future_array);
    const [loading,IsLoad] = useState(false);

    if(future.length > 0) {
        const loader = loading?<Loader />:null; 
        return( 
            <>
            {loader}
                {future.map(function(ftr, idx){
                    var date=ftr.the_date;
                    let m_class = 'badge badge-pill badge-fill-success';
                    let a_class = 'badge badge-pill badge-fill-success';
                    let e_class = 'badge badge-pill badge-fill-success';
                    if(ftr.tokencount_m === 0 && ftr.m_maxtoken === 0) {
                        m_class = "badge badge-pill badge-fill-default";
                    }
                    if(ftr.tokencount_a === 0 && ftr.a_maxtoken === 0) {
                        a_class = "badge badge-pill badge-fill-default";
                    }
                    if(ftr.tokencount_e === 0 && ftr.e_maxtoken === 0) {
                        e_class = "badge badge-pill badge-fill-default";
                    }    
                return (
                    <tr style={{cursor:"pointer"}} key={idx}>
                            <td className='future-td'><a onClick={()=>data.getSearchData(date)}>{Moment(date).format('DD-MM-YYYY')}</a></td>
                            <td className='future-td'><a onClick={()=>data.getSearchData(date)}><div className={m_class}>{ftr.tokencount_m}/{ftr.m_maxtoken}</div></a></td>
                            <td className='future-td'><a onClick={()=>data.getSearchData(date)}><div className={a_class}>{ftr.tokencount_a}/{ftr.a_maxtoken}</div></a></td>
                            <td className='future-td'><a onClick={()=>data.getSearchData(date)}><div className={e_class}>{ftr.tokencount_e}/{ftr.e_maxtoken}</div></a></td>
                    </tr>
                );
                })}
            </>
        )
    } else {
        return(
            <tr>
              <td colSpan="4"><p className="available-text-red" >No availability set for future 3 days</p></td>
            </tr>
        );
    }
    
}
export default FutureAvailability;
