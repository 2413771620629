/* eslint-disable jsx-a11y/heading-has-content */
import '../Style.css';
import DailyAvailabilityContent from './dailyavailabilitycontent';
import NoresultsFound from './NoresultsFound';
import FutureAvailability from './futureavailability';
import moment from 'moment';
import Loader from './loader';
import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import RestHelper from '../RestHelper';
import BookingsByDateComponent from './BookingsByDateComponent';
// minimum date setting
let minDate = moment().format('YYYY-MM-DD');
let maxDate = moment().add(30, 'days').format('YYYY-MM-DD');

function backRedirect() {
  let date = localStorage.getItem('the_date');
  let cat = localStorage.getItem('category');
  let spez = localStorage.getItem('specialization');
  if (localStorage.getItem('category') !== 'undefined' && localStorage.getItem('specialization') !== 'undefined') {
    window.location.href = '/search/' + date + '/' + cat + '/' + spez;
  } else if (localStorage.getItem('category') !== 'undefined') {
    window.location.href = '/search/' + date + '/' + cat;
  } else {
    window.location.href = '/search/' + date;
  }

  //  window.location.href='/search/'+date;
  //  if(date && !cat && !spec) {
  //     window.location.href='/search/'+date;
  //  } else if(date && cat && !spec) {
  //    window.location.href='/search/'+date+'/'+cat;
  //  } else if(date && cat && spec) {
  //    window.location.href='/search/'+date+'/'+cat+'/'+spec;
  //  }
}
function ProfileData(data) {
  const [loading, IsLoad] = useState(false);
  const result = data.details;
  const future = data.future;
  const date = data.search_date;
  const [search_date, setSearchDate] = useState(date);
  const [bookingInfo, setBookingInfo] = useState('');

  useEffect(() => {
    getSearchData(data.search_date);
  }, [])
  async function getSearchData(date) {
    setSearchDate(date);
    IsLoad(true);
    let client_id = document.getElementById("client_id").value;

    let container = document.getElementById('tableData');
    let tabledata = createRoot(container);  // Create a root.
    let container1 = document.getElementById('futureid');
    let futurelist = createRoot(container1);  // Create a root.
    let apiname = "search";
    let method = "post";
    let body = {
      sch_date: date,
      client_id: client_id
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if (response) {
        getBookingInfoByDate(date);
        let response_1 = '';
        if (response != '' && response.result) {
          response_1 = response.result;
        }
        let future = '';
        if (response != '' && response.future_availability) {
          future = response.future_availability;
        };

        futurelist.render(<FutureAvailability future={future} getSearchData={getSearchData} />);
        if (response_1.length) {
          tabledata.render(<DailyAvailabilityContent daily={response_1} customDataObject={response.customDataObject} getBookingInfoByDate={getBookingInfoByDate} />);
        } else {
          tabledata.render(<NoresultsFound />);
        }
      }
    } catch (err) {
      console.log(err);
      IsLoad(false);
    }
  }

  // function for getting booking info
async function getBookingInfoByDate(date) {
  let apiname = "getBookingInfoByDate";
  let method = "post";
  let client_id = document.getElementById("client_id").value;
  let body = {
    booking_date: date,
    client_id: client_id
  };
  try {
    let response = await RestHelper(apiname, method, body);
    if (response) {
      if(response.result.length > 0) {
        setBookingInfo(response.result);
      } else {
        setBookingInfo('');
      }
    }
    IsLoad(false);
  } catch (err) {
    console.log(err);
    IsLoad(false);
  }
}

async function CancelToken(token_uuid) {
  let session_key = localStorage.getItem('session_key');

  if(session_key) { 
      var returnValue = window.confirm('Are you sure you want to cancel this token?');
      if(returnValue === true) {
          IsLoad(true);
          let apiname = "cancelToken";
          let method = "post";
          let body = {
            token_uuid: token_uuid
          };
          try {
              let response = await RestHelper(apiname, method, body);
              if (response) {
                  if(response.code === 200) {
                      getBookingInfoByDate(search_date);
                      getSearchData(search_date);
                  } else {
                    alert(response.message)
                  }
              }
              IsLoad(false);
          } catch (err) {
          console.log(err);
          IsLoad(false);
          }
      } else {
          IsLoad(false); 
      }
  } else {
      alert('Please login to book your slot');
      IsLoad(false);
 
  }
}

  const loader = loading ? <Loader /> : null;
  return (
    <>
      {loader}
      <div className="container token-container" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <div className="row justify-content-center pr-fs-wrapper">
          <div className="col-md-6 pr-border-right">
            <div className='row'>
              <div className="col-4 pr-align-self">
                <span className='pr-circle'>
                  <i className="fa fa-user pr-user-i"></i>
                </span>
              </div>
              <div className="col-8 pr-adjust-left">
                <input type="hidden" value={result.client_id} id="client_id" />
                <h2 className="pr-name" id="name">{result.name}</h2>
                <h6 className='pr-sps'>{result.specialization}  </h6>
                <h6 className='pr-cadd'>{result.company_address}</h6>
                <div className='row'>
                  <div className='col-md-6'>
                    <h6 className='pr-cmp'>{result.company_phone}</h6>
                  </div>
                  <div className='col-md-6'>
                    <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${result.company_location},${result.company_address}`} style={{cursor: "pointer"}}><h6 className='pr-cmp-location text-right'><i className='fa fa-map-marker-alt outlined-icon'></i> View location</h6></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <h4 className="pr-future">Future Availability</h4>
                <table className="w-hours list-unstyled table">
                  <thead className="future-headings">
                    <tr>
                      <th className='future-th'>Date</th>
                      <th className='future-th'>Morning</th>
                      <th className='future-th'>Afternoon</th>
                      <th className='future-th'>Evening</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }} id="futureid">
                    {/* Future availability data */}
                    <FutureAvailability future={future} getSearchData={getSearchData} />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className='col-md-12 pr-sd-wrapper'>

            <div className='dr-wrapper'>
              <h4 className="mb-3 dr-head">Check Availability</h4>
              <div className="form-group">
                <input className="form-control dr-search-input" id="date_value" value={search_date} onChange={(e) => setSearchDate(e.target.value)} min={minDate} max={maxDate} type="date" />
              </div>
              <div className="form-group">
                <button className="btn btn-danger dr-search-btn" type="button" onClick={() => getSearchData(search_date)} >
                  <i className="fa fa-search mobile-only" aria-hidden="true"></i>
                  <span className='mobile-hide'>Search</span>
                </button>
              </div>
            </div>
            <div className='row justify-content-center' style={{padding: 17}}>
              <div className="col-md-6 col-sm-12">
                <div className='pr-book-view'>
                <h4 className="mb-3 dr-slot-head">Available Hours 
                  {/* {moment(searchDate).format('DD-MM-YYYY')} */}
                </h4>
                <div className="w-hours list-unstyled table booking-list-tbl" id="tableData">

                </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
              <div className='pr-book-view pr-bookings-wrapper'>
                <h4 className="mb-3 dr-slot-head">Bookings
                </h4>
                  <div className="w-hours list-unstyled table booking-list-tbl booked-list" id="myBookings">
                    <BookingsByDateComponent bookingInfo={bookingInfo} CancelToken={CancelToken} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}
export default ProfileData;
