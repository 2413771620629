/* eslint-disable jsx-a11y/heading-has-content */
import '../Style.css';
import TokenShift from './tokenShift';
import { createRoot } from 'react-dom/client';
import { useState, useEffect } from 'react';
import moment from 'moment';
import Loader from './loader';
import RestHelper from '../RestHelper';


function TokenInfoComponent() {
  
  // minimum date setting
  let today = moment().format('YYYY-MM-DD');
  let current_date = today;
  const [loading, IsLoad] = useState(false);
  const [searchDate, setSearchDate] = useState(current_date);
  useEffect(() => {
    getBookingInfo(searchDate);
  }, []);
  async function getBookingInfo(searchDate) {
    IsLoad(true);
    let apiname = "getBookingInfo";
    let method = "post";
    let body = {
      date: searchDate
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if (response) {
        let response_1 = response.customDataObject;
        let container = document.getElementById('tableData');
        let root = createRoot(container);  // Create a root.
        root.render(<TokenShift customDataObject={response_1} />);
      }
      IsLoad(false);
    } catch (err) {
      console.log(err);
      IsLoad(false);
    }
  }
  const loader = loading ? <Loader /> : null;
  return (
    <>
      {loader}
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <h1 className="text-capitalize mb-5 text-lg">Start your session</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="appoinment section">
        <div className="container token-container">
          <div className="row justify-content-center">
            <div className="col-md-12 dr-main-wrapper">
              <div className="row">
                <div className="col-md-12 dr-padding">
                  <div className="appoinment-wrap mt-5 mt-lg-0">
                    <div className='dr-wrapper'>
                      <h4 className="mb-3 dr-head">Check Session</h4>
                      <div className="form-group"><input className="form-control dr-search-input" id="date_value" type="date" min={current_date} defaultValue={current_date} onChange={(e) => setSearchDate(e.target.value)} /></div>
                      <div className="form-group">
                        <button className="btn btn-danger dr-search-btn" type="button" onClick={() => getBookingInfo(searchDate)}>
                          {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                          Search
                        </button>
                      </div>
                    </div>
                    <div className='row justify-content-center'>
                      <div className="col-md-10 col-sm-12 dr-book-view">
                        <h4 className="mb-3 dr-slot-head">Booking Details
                          {/* {moment(searchDate).format('DD-MM-YYYY')} */}
                        </h4>
                        <table className="w-hours list-unstyled table booking-list-tbl" id="tableData">

                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );

}
export default TokenInfoComponent;
