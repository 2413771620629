
function ContactContent() {
    return (
      <>
       <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
            <div className="row">
            <div className="col-md-12">
                <div className="block text-center">
                <span className="text-white">Contact Us</span>
                <h1 className="text-capitalize mb-5 text-lg">Get in Touch</h1>
                </div>
            </div>
            </div>
        </div>
        </section>

        <section className="section contact-info pb-0">
        <div className="container">
            <div className="row">
            <div className="col-lg-4 col-md-6">
                <div className="contact-block mb-4 mb-lg-0">
                <i className="icofont-live-support"></i>
                <h5>Call Us</h5>
                +91 8921653212
                </div>
            </div>
            <div className="col-lg-4 col-md-6">
                <div className="contact-block mb-4 mb-lg-0">
                <i className="icofont-support-faq"></i>
                <h5>Email Us</h5>
                Support@bookatoken.com<br />
                </div>
            </div>
            <div className="col-lg-4 col-md-12">
                <div className="contact-block mb-4 mb-lg-0">
                <i className="icofont-location-pin"></i>
                <h5>Location</h5>
                <p>C1-10, Chaitanya Building, Infopark, Cherthala</p>
                </div>
            </div>
            </div>
        </div>
        </section>

        <section className="section clients">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="section-title text-center">
                  <h2>About BookAToken.com</h2>
                  <div className="divider mx-auto my-4"></div>
                  <p>
              Powered by<br /> <h4 className="mb-3">Obidos Technologies (P) Ltd</h4>Office No: C1-10, C-Wing, First Floor,<br />
     Chaitanya Building, Infopark,<br/>
     K.R.Puram P.O., Cherthala-688556<br/>
     Alappuzha, Kerala, INDIA
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> 
      </>
    );
}
export default ContactContent;