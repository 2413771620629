import Header from '../Components/header';
import Footer from '../Components/footer';
import PatientDetailsComponent from '../Components/patientDetailsComponent';

function PatientDetails() {
  return (
    <>
      <Header /><PatientDetailsComponent /><Footer />
    </>
  );
}
export default PatientDetails;