
function TokenShift(data) {
    let shift = data.customDataObject;
    let date = shift.date;
    let isany = 0;
    if(shift.morning_flag === 1 || shift.afternoon_flag === 1 || shift.evening_flag === 1){
        isany = 1;
    }
    return (
        <tbody id="dataShift">
            {shift.morning_flag === 1 ? (
                <div className="dr-row">
                    <div className="row row-grey">
                        <div className="col-md-9">
                            <div className="available-text">
                                <div className="row">
                                    <p className="dr-sub-slot">{shift.morning} Slot - <span className="dr-slot-time-period">{shift.morning_start} to {shift.morning_end}</span></p>
                                </div>
                                <div className="row dr-remains">
                                    Remaining : {shift.remaining_token_m}
                                </div>
                                <div className="row dr-btns justify-content-center">
                                    <button className="btn dr-btn-all" type="button" onClick={ () => { window.location.href = 'patientdetails/' + date + '/M'; } } >All Patients</button>
                                    <button className="btn dr-btn-add" type="button" >Add Patient</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 dr-border-left">
                            {shift.live_token_m !== '0' && shift.token_nos_m !== '0' ? (
                                <div className="row justify-content-center">
                                    <div className="col-md-12" align="center">
                                        <p className="dr-token-head">Token</p>
                                    </div>
                                    <div className="col-md-12" align="center">
                                        <h5 className="dr-token-no">{shift.live_token_m}/{shift.token_nos_m}</h5>
                                    </div>
                                    <div className="col-md-12" align="center">
                                        <a href={"tokendetails/" + date + "/M"} style={{ cursor: "pointer" }} className="dr-token-head-arrow">Go to session <i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            ) : (
                                <div className="row justify-content-center">
                                    <div className="col-md-12" align="center">
                                        <p className="dr-token-head">Token</p>
                                    </div>
                                    <div className="col-md-12" align="center">
                                        <h5 className="dr-token-no">_ _</h5>
                                    </div>
                                    <div className="col-md-12" align="center">
                                        <a className="dr-token-head-arrow dr-disable">Go to session <i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (null)

            }

            {shift.afternoon_flag === 1 ? (
                <div className="dr-row">
                    <div className="row row-grey">
                        <div className="col-md-9">
                            <div className="available-text">
                                <div className="row">
                                    <p className="dr-sub-slot">{shift.afternoon} Slot - <span className="dr-slot-time-period">{shift.afternoon_start} to {shift.afternoon_end}</span></p>
                                </div>
                                <div className="row dr-remains">
                                    Remaining : {shift.remaining_token_a}
                                </div>
                                <div className="row dr-btns justify-content-center" style={{ marginTop: 20 }}>
                                    <button className="btn dr-btn-all" type="button" onClick={ () => { window.location.href = 'patientdetails/' + date + '/A'; } } >All Patients</button>
                                    <button className="btn dr-btn-add" type="button" >Add Patient</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            {shift.live_token_a !== '0' && shift.token_nos_a !== '0' ? (
                                <div className="row justify-content-center">
                                    <div className="col-md-12" align="center">
                                        <p className="dr-token-head">Token</p>
                                    </div>
                                    <div className="col-md-12" align="center">
                                        <h5 className="dr-token-no">{shift.live_token_a}/{shift.token_nos_a}</h5>
                                    </div>
                                    <div className="col-md-12" align="center">
                                        <a href={"tokendetails/" + date + "/A"} style={{ cursor: "pointer" }} className="dr-token-head-arrow">Go to session <i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            ) : (
                                <div className="row justify-content-center">
                                    <div className="col-md-12" align="center">
                                        <p className="dr-token-head">Token</p>
                                    </div>
                                    <div className="col-md-12" align="center">
                                        <h5 className="dr-token-no">_ _</h5>
                                    </div>
                                    <div className="col-md-12" align="center">
                                        <a className="dr-token-head-arrow dr-disable">Go to session <i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (null)
            }

            {shift.evening_flag === 1 ? (

                <div className="dr-row">
                    <div className="row row-grey">
                        <div className="col-md-9">
                            <div className="available-text">
                                <div className="row">
                                    <p className="dr-sub-slot">{shift.evening} Slot - <span className="dr-slot-time-period">{shift.evening_start} to {shift.evening_end}</span></p>
                                </div>
                                <div className="row dr-remains">
                                    Remaining : {shift.remaining_token_e}
                                </div>
                                <div className="row dr-btns justify-content-center">
                                    <button className="btn dr-btn-all" type="button" onClick={ () => { window.location.href = 'patientdetails/' + date + '/E'; } } >All Patients</button>
                                    <button className="btn dr-btn-add" type="button" >Add Patient</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            {shift.live_token_e !== '0' && shift.token_nos_e !== '0' ? (
                                <div className="row justify-content-center">
                                    <div className="col-md-12" align="center">
                                        <p className="dr-token-head">Token</p>
                                    </div>
                                    <div className="col-md-12" align="center">
                                        <h5 className="dr-token-no">{shift.live_token_e}/{shift.token_nos_e}</h5>
                                    </div>
                                    <div className="col-md-12" align="center">
                                        <a href={"tokendetails/" + date + "/E"} style={{ cursor: "pointer" }} className="dr-token-head-arrow">Go to session <i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            ) : (
                                <div className="row justify-content-center">
                                    <div className="col-md-12" align="center">
                                        <p className="dr-token-head">Token</p>
                                    </div>
                                    <div className="col-md-12" align="center">
                                        <h5 className="dr-token-no">_ _</h5>
                                    </div>
                                    <div className="col-md-12" align="center">
                                        <a className="dr-token-head-arrow dr-disable">Go to session <i className="fa fa-angle-right"></i></a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            ) : (null)
            }
            {isany == 0 ? (
                <div className="row justify-content-center">
                    <div className="col-md-12 dr-not-avl-wrapper">
                        <h4 className="dr-not-avl">No Sessions Available </h4>
                    </div>
                </div>
            ) : (null)}
        </tbody>
    );
}
export default TokenShift;