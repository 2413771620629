import { useState } from 'react';
import DailyAvailabilityContent from './dailyavailabilitycontent';
import Loader from './loader';
import { createRoot } from 'react-dom/client';
import RestHelper from '../RestHelper';
import DoctorBookingComponent from './doctorBookingPopup';

function ShiftComponent(prop) {
    const [loading, IsLoad] = useState(false);
    const [ismodalDisplay, setIsModalDisplay] = useState(false);
    const status = prop.status;
    const token_count = prop.token_count;
    const time_from = prop.time_from;
    const time_to = prop.time_to;
    const date = prop.date;
    const client_id = prop.client_id;
    let slot = '';
    if (status === 'Morning') {
        slot = 'M';
    } else if (status === 'Afternoon') {
        slot = 'A';
    } else {
        slot = 'E';
    }
    async function BookAtoken(patientname) {
        IsLoad(true);
        let session_key = localStorage.getItem('session_key');
        if (session_key) {

            let container = document.getElementById('tableData');
            let tabledata = createRoot(container);  // Create a root.

            let apiname = "requestToken";
            let method = "post";
            let body = {
                date: date,
                client_id: client_id,
                slot: slot,
                patient_name: patientname
            };
            try {
                let response = await RestHelper(apiname, method, body);
                if (response) {
                    if (response.code === 200) {
                        let apiname = "search";
                        let method = "post";
                        let body = {
                            sch_date: date,
                            client_id: client_id
                        };
                        try {
                            let response = await RestHelper(apiname, method, body);
                            let response_1 = response.result;
                            if (response_1.length) {
                                tabledata.render(<DailyAvailabilityContent daily={response_1} customDataObject={response.customDataObject} getBookingInfoByDate={prop.getBookingInfoByDate} />);
                                prop.getBookingInfoByDate(date);
                            } else {
                                //ReactDOM.render(<NoDataFound  />,document.getElementById("appendResult"));
                            }
                        } catch (err) {
                            console.log(err);
                            IsLoad(false);
                        }
                    } else {
                        alert(response.message);
                    }
                }
                IsLoad(false);
            } catch (err) {
                console.log(err);
                IsLoad(false);
            }

        } else {
            IsLoad(false);
            alert('Please login to book your slot');
            window.location.href = '/customer'
        }
    }
    const flag = prop.flag;
    if (flag === 0) {
        const loader = loading ? <Loader /> : null;
        const time_period = `${time_from} to ${time_to}`;
        function changeModalDisplay() {
            setIsModalDisplay(!ismodalDisplay);
        }
        return (
            <>{loader}<>
                {prop.display_flag === 1 ? (
                    <div className="dr-row">
                        <div className="row row-grey pr-row-1">
                            <div className="col-md-12">
                                <div className="row">
                                    <p className="bk-sub-slot">{status} Slot - <span className="bk-slot-time-period">{time_period}</span></p>
                                </div>
                                <div className="row">
                                    <div className="col-8" align="center">
                                        <h4 className='token-txt'>Token : <span>{token_count} </span></h4>
                                    </div>
                                    <div className="col-4" align="center">
                                        <button className="btn-dr-book" type="button" onClick={() => { changeModalDisplay() }}>Book</button>
                                        {/* onClick={()=>BookAtoken(date,client_id,slot)} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {ismodalDisplay ? (
                            <DoctorBookingComponent
                                time_period={time_period}
                                status={status}
                                token_count={token_count}
                                onBook={BookAtoken}
                                onClose={changeModalDisplay}
                            />
                        ) : (null)}
                    </div>
                ) : (
                    null
                )}
            </>
            </>
        );
    } else {
        return (<></>);
    }
}

export default ShiftComponent;