/* eslint-disable jsx-a11y/anchor-is-valid */
import '../Style.css';
import { useState, useEffect } from "react";
import React from 'react';
import Loader from './loader';
import RestHelper from '../RestHelper';
// https://bookatoken.com/api1.0/api/

function sessionDestroy() {
  localStorage.removeItem('session_key');
  localStorage.removeItem('type');
  window.location.href = "/";
}

function Header() {
  const [loading, IsLoad] = useState(false);
  // state for storing logined user name

  const [name, setName] = useState();
  const [type, setType] = useState('');

  useEffect(() => {
    
    if (localStorage.getItem('session_key')) {
      getProfileData();
    }
  }, []);
 
  async function getProfileData() {
    IsLoad(true);
    let apiname = "getProfileInfo";
    let method = "post";
    let body = {
      date: ''
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if (response) {
        if (response.code === 200) {
          let result = response.result;
          setName(result.name);
          setType(result.type);
        } else {
          alert(response.message);
        }
      }
      IsLoad(false);
    } catch (err) {
      console.log(err);
      IsLoad(false);
    }
   
  }

  const loader = loading ? <Loader /> : null;
  return (
    <header>
      {loader}
      <div className="header-top-bar">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <ul className="top-bar-info list-inline-item pl-0 mb-0">
                <li className="list-inline-item"><a href="mailto:Support@bookatoken.com"><i className="icofont-support-faq mr-2"></i>support@bookatoken.com</a></li>
                <li className="list-inline-item"><i className="icofont-location-pin mr-2"></i>C1-10, Chaitanya Building, Infopark, Cherthala</li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="text-lg-right top-right-bar mt-2 mt-lg-0">
                <a href="tel:+918921653212" >
                  <span>Call Now : </span>
                  <span className="h4">+91 8921653212</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navigation" id="navbar">
        <div className="container">
          <a className="navbar-brand" href="/" style={{width:"54%"}}>
            <img src={process.env.REACT_APP_IMAGE_URL + 'bookatoken.jpg'} alt="No logo" className="img-fluid" />
          </a>

          <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarmain" aria-controls="navbarmain" aria-expanded="false" aria-label="Toggle navigation">
            <span className="icofont-navigation-menu"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarmain">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a className="nav-link" href="/">Home</a>
              </li>
              {type !== '1' ? (
                <li className="nav-item"><a className="nav-link" href="/search">Search</a></li>
              ) : (null)}
              {type === '1' ? (
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="/set_daily_availability" id="dropdown02" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Set Availability <i className="icofont-thin-down"></i></a>
                  <ul className="dropdown-menu" aria-labelledby="dropdown02">
                    <li><a className="dropdown-item" href="/set_daily_availability">Daily Availability</a></li>
                    <li><a className="dropdown-item" href="/set_weekly_availability">Weekly Availability</a></li>

                  </ul>
                </li>
              ) : (null
              )}
              {type === '1' ? (
                <li className="nav-item"><a className="nav-link" href="/tokeninfo">Token Info</a></li>
              ) : (null
              )}
              {type === '0' ? (
                <li className="nav-item"><a className="nav-link" href="/token-list">My Bookings</a></li>
              ) : (null
              )}
              <li className="nav-item"><a className="nav-link" href="/contact">Contact</a></li>
              {localStorage.getItem('session_key') ? (
                <>
                  {/* <li className="nav-item"><a className="nav-link" onClick={sessionDestroy}><button className="btn btn-success btn-sm">Logout <i className="fas fa-sign-out-alt" title="Logout" style={{fontSize:"20px"}} aria-hidden="true"></i></button></a></li> */}
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" id="dropdown02" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-user-circle" aria-hidden="true" style={{ fontSize: "32px", color: "#223a66" }}></i></a>
                    <ul className="dropdown-menu" aria-labelledby="dropdown02">
                      <li><a className="dropdown-item" href="#" style={{ color: "#e12454" }}><b>{name}</b></a></li>
                      <li><a className="dropdown-item" href="/myprofile" style={{ color: "black" }}><b>My Profile</b></a></li>
                      <li><a className="dropdown-item" onClick={sessionDestroy} style={{ cursor: "pointer" }}><b>LOGOUT </b><i className="fas fa-sign-out-alt" title="Logout" style={{ fontSize: "20px" }} aria-hidden="true"></i></a></li>

                    </ul>
                  </li>
                </>
              ) : (
                <>
                 <li className="nav-item"><a className="nav-link" href="/client">Client</a></li>
                 <li className="nav-item"><a className="nav-link" href="/customer"><button className="btn btn-danger btn-sm">Login</button></a></li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
export default Header;