function BookingsByDateComponent(props) {
    const bookings = props.bookingInfo;
    
    return (
        <>
            <table className="table table-borderless">
                <thead>
                    <tr className="bk-tbl-row">
                        <th scope="col">Name of patient</th>
                        <th scope="col">Token number</th>
                        <th scope="col">Slot</th>
                        <th></th>
                    </tr>
                </thead>
                {bookings !== '' ? (
                    <tbody>
                         {bookings.map(function(info, idx){
                            let slot = '';
                            if(info.slot === 'M') {
                                slot = 'Morning';
                            } else if(info.slot === 'A') {
                                slot = 'Afternoon';
                            } else {
                                slot = 'Evening';
                            }
                            return(
                                <tr className="bk-tbl-body-row" key={idx}>
                                    <td>{info.patient_name}</td>
                                    <td className="bk-td-bold">{info.token_no}</td>
                                    <td><span className="bk-td-slot">{slot}</span></td>
                                    <td><a className="bk-cancel" onClick={()=>props.CancelToken(info.token_uuid)} ><i className="fa fa-trash"></i></a></td>
                            </tr>);
                         })}
                    </tbody>
                 ) : (
                    <tbody>
                        <tr className=" justify-content-between">
                            <td colSpan="4" className="available-text-red">No bookings</td>
                        </tr>
                    </tbody>
                )}
            </table>
        </>
    );
}

export default BookingsByDateComponent;