
function Footer() {
  return (
      <footer className="footer section gray-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mr-auto col-sm-6">
              <div className="widget mb-5 mb-lg-0">
                <div className="logo mb-4">
                  <img src="assets/images/logo.png" alt="" className="img-fluid footer-logo"/>
                </div>
                <p>We are constantly adding more service providers to our system. You will be able to book a token based on their availability very soon.</p>
      
                <ul className="list-inline footer-socials mt-4">
                  <li className="list-inline-item"><a href="https://www.facebook.com"><i className="icofont-facebook"></i></a></li>
                  <li className="list-inline-item"><a href="https://twitter.com"><i className="icofont-twitter"></i></a></li>
                  <li className="list-inline-item"><a href="https://www.linkedin.com/"><i className="icofont-linkedin"></i></a></li>
                </ul>
              </div>
            </div>
      
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="widget mb-5 mb-lg-0">
                <h4 className="text-capitalize mb-3">Services</h4>
                <div className="divider mb-4"></div>
      
                <ul className="list-unstyled footer-menu lh-35">
                  <li><a href="/search">Doctors </a></li>
                  <li><a href="/search">Charted accountants</a></li>
                  <li><a href="/search">Schools</a></li>
                  <li><a href="/search">Service Station</a></li>
                </ul>
              </div>
            </div>
      
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="widget mb-5 mb-lg-0">
                <h4 className="text-capitalize mb-3">Support</h4>
                <div className="divider mb-4"></div>
      
                <ul className="list-unstyled footer-menu lh-35">
                  <li><a href="/search">Terms & Conditions</a></li>
                  <li><a href="/search">Privacy Policy</a></li>
                  <li><a href="/search">Company Support </a></li>
                  <li><a href="/search">FAQ</a></li>
                </ul>
              </div>
            </div>
      
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="widget widget-contact mb-5 mb-lg-0">
                <h4 className="text-capitalize mb-3">Get in Touch</h4>
                <div className="divider mb-4"></div>
      
                <div className="footer-contact-block mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-email mr-3"></i>
                    <span className="h6 mb-0">Support Available for 24/7</span>
                  </div>
                  <h4 className="mt-2"><a href="mailto:support@bookatoken.com">support@bookatoken.com</a></h4>
                </div>
      
                <div className="footer-contact-block">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-support mr-3"></i>
                    <span className="h6 mb-0">Mon to Fri : 09:30 - 18:00</span>
                  </div>
                  <h4 className="mt-2"><a href="tel:+918921653212">+91 8921653212</a></h4>
                </div>
              </div>
            </div>
          </div>
          
          <div className="footer-btm py-4 mt-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6">
                <div className="copyright">
                  &copy;2021-2022 Copyright Reserved <a href="https://www.obidostech.com/" target="_blank" rel="noreferrer">Obidos Technologies (P) Ltd</a>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="subscribe-form text-lg-right mt-5 mt-lg-0">
                  <form action="/" className="subscribe">
                    <input type="text" className="form-control" placeholder="Your Email address" />
                    <a href="/" className="btn btn-main-2 btn-round-full">Subscribe</a>
                  </form>
                </div>
              </div> */}
            </div>
      
            <div className="row">
              <div className="col-lg-4">
                <a className="backtop js-scroll-trigger" href="#top">
                  <i className="icofont-long-arrow-up"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
  
  )
}
export default Footer;