import '../Style.css';
import { useState, useEffect } from 'react';
import Loader from './loader';
import RestHelper from '../RestHelper';
import { useParams } from 'react-router-dom';

function PatientDetailsComponent() {
  const [loading, IsLoad] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);  // Pagination state
  const [patientsPerPage] = useState(10);  // Number of patients per page
  const [maxPageNumbersToShow] = useState(5); // Max number of page links to show at once
  let params = useParams();
  const [searchDate] = useState(params.the_date);
  const [slot] = useState(params.slot);

  useEffect(() => {
    getPatientDetails();
  }, []);

  async function getPatientDetails() {
    IsLoad(true);
    let apiname = "getBookingDetails";
    let method = "post";
    let body = {
      booking_date: searchDate,
      slot: slot
    };
    try {
      let response = await RestHelper(apiname, method, body);
      if (response) {
        setPatientDetails(response.result);
      }
      IsLoad(false);
    } catch (err) {
      console.log(err);
      IsLoad(false);
    }
  }

  // Get current patients for pagination
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
  const currentPatients = patientDetails.slice(indexOfFirstPatient, indexOfLastPatient);

  // Calculate total pages
  const totalPages = Math.ceil(patientDetails.length / patientsPerPage);

  // Pagination controls
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  const prevPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));

  // Generate page numbers based on the current page and max numbers to show
  const pageNumbers = [];
  const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
  const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const loader = loading ? <Loader /> : null;
  let sl = indexOfFirstPatient + 1;  // Adjust serial number according to pagination

  return (
    <>
      {loader}
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <h1 className="text-capitalize mb-5 text-lg">Patient Details</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="appoinment section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <h5 className="mb-3 d-flex flex-column" id="search_text">All Patients</h5>
              <div className="table-responsive-md">
                <table className="table pt-table">
                  <thead>
                    <tr className='pt-row'>
                      <th scope="col">No.</th>
                      <th scope="col">Patient Name</th>
                      <th scope="col" className='text-center'>Token No.</th>
                      <th scope="col">Email</th>
                      <th scope="col" className='text-center'>Status</th>
                    </tr>
                  </thead>
                  {currentPatients.length > 0 ? (
                    <tbody>
                      {currentPatients.map((info, idx) => (
                        <tr key={idx}>
                          <td>{sl++}</td>
                          <td>{info.patient_name}</td>
                          <td className='text-center'>{info.token_no}</td>
                          <td>{info.email}</td>
                          <td className='text-center'>
                            {info.iscalled === 0 ? (
                              <span className='not-called-check'>
                                <i className='fa fa-check'></i>
                              </span>
                            ) : (
                              <span className='called-check'>
                                <i className='fa fa-check'></i>
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr className=" justify-content-between">
                        <td colSpan="6" className="available-text-red">No patients</td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
              {/* Pagination Links */}
              <nav>
                <ul className="pagination justify-content-center">
                  {/* Previous Button */}
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={prevPage}>
                      &laquo; Prev
                    </button>
                  </li>

                  {/* First Page Link */}
                  {startPage > 1 && (
                    <>
                      <li className="page-item">
                        <button className="page-link" onClick={() => paginate(1)}>
                          1
                        </button>
                      </li>
                      {startPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    </>
                  )}

                  {/* Page Number Links */}
                  {pageNumbers.map((number) => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                      <button onClick={() => paginate(number)} className="page-link">
                        {number}
                      </button>
                    </li>
                  ))}

                  {/* Last Page Link */}
                  {endPage < totalPages && (
                    <>
                      {endPage < totalPages - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                      <li className="page-item">
                        <button className="page-link" onClick={() => paginate(totalPages)}>
                          {totalPages}
                        </button>
                      </li>
                    </>
                  )}

                  {/* Next Button */}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={nextPage}>
                      Next &raquo;
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PatientDetailsComponent;
