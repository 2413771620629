import { useState } from "react";


function DoctorBookingComponent({ time_period, status, token_count, onBook, onClose }) {
  const [patientName, setPatientName] = useState('');
  return (
    <div className="modal"  tabIndex="-1" role="dialog" style={{display:"block"}} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content pr-modal-wrapper bk-adjust-width">
          <div className="modal-header">
            <h5 className="modal-title bk-head">Booking details</h5>
            {/* <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button> */}
          </div>
          <div className="modal-body">
            <p className="bk-sub-slot">{status} Slot - <span className="bk-slot-time-period">{time_period}</span></p>
            <h4 className='token-txt'>Token : <span>{token_count} </span></h4>
            <div className="form-group popup-form-group">
              <input className="form-control dr-search-input popup-form-input" value={patientName} onChange={(e) => setPatientName(e.target.value)} type="text" placeholder="Enter patient name" maxLength={100} />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-cancel" data-dismiss="modal" onClick={onClose}>Cancel</button>
            <button type="button" className="btn btn-confirm" onClick={ () => { onBook(patientName) } }>Confirm Booking</button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DoctorBookingComponent;